import $ from "jquery";

function getImageFn(planName) {
    return "/assets/Dateien/Firmen/isometrien/"+planName+".svg";
}

$(function() {

    const $planimage = $(".planimage");
    const $planlinks = $("[data-plan]");
    const $popups = $(".firmenpopup");

    $(".stockmieter-container").on("click", "[data-plan]", function() {
        const $planlink = $(this);
        const planName = $planlink.data("plan");
        const imagefn = getImageFn(planName);
        $planimage.attr("src", imagefn);

        $planlinks.removeClass("active");
        $planlink.addClass("active");


        const popupAlias = planName.toLowerCase().replaceAll("_", "-");
        const $popup = $(".firmenpopup[data-name="+popupAlias+"]");
        $popups.hide();
        if($popup.length > 0) {
            $popup.fadeIn(200);
        }
    });
    
});