import $ from "jquery";

$(function() {
    const $toggleButton = $(".menuicon");

    $toggleButton.on("click", function() {
        setTimeout(function() {
            const offcanvasVisible = $("#menu-offcanvas").hasClass("is-open");
            $toggleButton.toggleClass("isopen", offcanvasVisible);
        }, 100);
    });
});