import $ from "jquery";
import "jquery.scrollto";

$(window).on("load", function() {
    const hash = window.location.hash;
    if(hash.length > 1) {
        const scrollTargetName = hash.substring(1);
        const $scrollTarget = $("[data-scrolltarget='"+scrollTargetName+"']");
        if($scrollTarget.length > 0) {
            $(window).scrollTo($scrollTarget, 1000, {
                offset: -30
            });
        }
    }
});