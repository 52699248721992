import smoothscroll from 'smoothscroll-polyfill'
import { ready } from "./utils.js";

ready(() => {
  const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent && navigator.userAgent.indexOf('CriOS') == -1 && navigator.userAgent.indexOf('FxiOS') == -1
  if (isSafari) {
    window.__forceSmoothScrollPolyfill__ = true
    smoothscroll.polyfill()
  }

  const articlesSlider = document.getElementById("slider-articles");
  if (articlesSlider) {
    new Slider(articlesSlider);
  }
});

class Slider {
  constructor(sliderEl) {
    const slidesWrapper = sliderEl.querySelector(".slides-wrapper");

    const arrowsContainer = sliderEl.querySelector(".arrows-container");
    const prevArrow = arrowsContainer.querySelector(".arrow-prev");
    const nextArrow = arrowsContainer.querySelector(".arrow-next");

    this.slidesWrapper = slidesWrapper;
    // this.arrowsContainer = arrowsContainer;
    this.prevArrow = prevArrow;
    this.nextArrow = nextArrow;
    this.arrowMousedown = false;
    this.longPressed = false;
    this.currentIntervalId = null;
    this.currentTimeoutId = null;
    this.moving = false;
    this.pos = { top: 0, left: 0, x: 0, y: 0 };

    prevArrow.addEventListener("pointerdown", this.pointerDownPrevHandler);
    nextArrow.addEventListener("pointerdown", this.pointerDownNextHandler);

    // prevents images from being dragged
    document.onmousedown = (e) => {
      if (e.preventDefault) {
        e.preventDefault();
      }
    };

    slidesWrapper.addEventListener("mousedown", this.mouseDownHandler);
    slidesWrapper.addEventListener("touchstart", this.mouseDownHandler);
    slidesWrapper.addEventListener("scroll", this.scrollHandler);

    // disable an arrow button, if necessary£
    const { scrollLeft, scrollWidth, clientWidth } = slidesWrapper;
    const maxScrollLeft = scrollWidth - clientWidth;
    if (scrollLeft == 0) {
      prevArrow.disabled = true;
    } else if (scrollLeft == maxScrollLeft) {
      nextArrow.disabled = true;
    }
  }

  getSlideWidth = () => {
    const clientWidth = this.slidesWrapper.clientWidth
    const containerWidth = this.slidesWrapper.scrollWidth
    // Calculate slide width with container gap
    const width = (containerWidth - clientWidth) / (this.slidesWrapper.children.length - 1)
    // 638px + 80px gap is maximum mobile breakpoint
    return width <= 716 ? width : 500
  };

  /*
    interaction with prev/next buttons
    */
  pointerDownPrevHandler = () => {
    this.arrowMousedown = true;
    this.currentTimeoutId = setTimeout(() => {
      if (this.arrowMousedown) {
        this.longPressed = true;
        this.animateScroll(-5, 10);
      }
    }, 1000);
    document.addEventListener("pointerup", this.pointerUpPrevHandler);
  };

  pointerUpPrevHandler = () => {
    if (this.longPressed) {
      window.clearInterval(this.currentIntervalId);
      this.currentIntervalId = null;
      this.longPressed = false;
    } else {
      // under time of timeout, it is a click
      this.slidesWrapper.scrollBy({ left: this.getSlideWidth() * -1, behavior: "smooth" });
    }
    window.clearTimeout(this.currentTimeoutId);
    this.currentTimeoutId = null;
    this.arrowMousedown = false;
    document.removeEventListener("pointerup", this.pointerUpPrevHandler);
  };

  pointerDownNextHandler = () => {
    this.arrowMousedown = true;
    this.currentTimeoutId = setTimeout(() => {
      if (this.arrowMousedown) {
        this.longPressed = true;
        this.animateScroll(5, 10);
      }
    }, 1000);
    document.addEventListener("pointerup", this.pointerUpNextHandler);
  };

  pointerUpNextHandler = () => {
    if (this.longPressed) {
      window.clearInterval(this.currentIntervalId);
      this.currentIntervalId = null;
      this.longPressed = false;
    } else {
      // under time of timeout, it is a click
      this.slidesWrapper.scrollBy({ left: this.getSlideWidth(), behavior: "smooth" });
    }
    window.clearTimeout(this.currentTimeoutId);
    this.currentTimeoutId = null;
    this.arrowMousedown = false;
    document.removeEventListener("pointerup", this.pointerUpNextHandler);
  };

  /*
   for dragging events
   */

  mouseDownHandler = (e) => {
    this.slidesWrapper.style.cursor = "grabbing";
    this.slidesWrapper.style.userSelect = "none";

    this.pos = {
      // The current scroll
      left: this.slidesWrapper.scrollLeft,
      top: this.slidesWrapper.scrollTop,
      // Get the current mouse position
      x: e.changedTouches ? e.changedTouches[0].clientX : e.clientX,
      y: e.changedTouches ? e.changedTouches[0].clientY : e.clientY,
    };

    this.slidesWrapper.addEventListener("mousemove", this.mouseMoveHandler);
    this.slidesWrapper.addEventListener("touchmove", this.mouseMoveHandler);
    this.slidesWrapper.addEventListener("mouseup", this.mouseUpHandler);
    this.slidesWrapper.addEventListener("touchend", this.mouseUpHandler);
  };

  mouseMoveHandler = (e) => {
    if (!e.changedTouches) {
      // How far the mouse has been moved
      const dx = e.clientX - this.pos.x;
      const dy = e.clientY - this.pos.y;
      // Scroll the element
      this.slidesWrapper.scrollLeft = this.pos.left - dx;
      this.slidesWrapper.scrollTop = this.pos.top - dy;
    } else {
      const dx = e.changedTouches[0].clientX - this.pos.x;
      const dy = e.changedTouches[0].clientY - this.pos.y;
      // Threshold is 10px horizontally
      if (!this.moving && Math.abs(dx) >= 10 && Math.abs(dx) > Math.abs(dy)) {
        e.preventDefault()
        e.stopPropagation()
        this.moving = true
        setTimeout(() => {
          this.moving = false
        }, 500)

        if (dx > 0) {
          this.slidesWrapper.scrollBy({ left: this.getSlideWidth() * -1, behavior: "smooth" });
        } else {
          this.slidesWrapper.scrollBy({ left: this.getSlideWidth(), behavior: "smooth" });
        }
      }
    }
  };

  mouseUpHandler = () => {
    this.slidesWrapper.removeEventListener("mousemove", this.mouseMoveHandler);
    this.slidesWrapper.removeEventListener("touchstart", this.mouseMoveHandler);
    this.slidesWrapper.removeEventListener("mouseup", this.mouseUpHandler);
    this.slidesWrapper.removeEventListener("touchend", this.mouseUpHandler);

    this.slidesWrapper.style.cursor = "grab";
    this.slidesWrapper.style.removeProperty("user-select");
  };

  scrollHandler = () => {
    const { scrollLeft, scrollWidth, clientWidth } = this.slidesWrapper;
    const maxScrollLeft = scrollWidth - clientWidth;
    if (scrollLeft == 0) {
      // reset, because with disabled button, it doesn't fire listeners anymore
      if (this.longPressed) {
        this.pointerUpPrevHandler();
      }
      this.prevArrow.disabled = true;
    } else if (scrollLeft == maxScrollLeft) {
      // reset, because with disabled button, it doesn't fire listeners anymore
      if (this.longPressed) {
        this.pointerUpNextHandler();
      }
      this.nextArrow.disabled = true;
    } else {
      if (this.prevArrow.disabled == true) {
        this.prevArrow.disabled = false;
      }
      if (this.nextArrow.disabled == true) {
        this.nextArrow.disabled = false;
      }
    }
  };

  animateScroll = (distancePerInterval, intervalTime) => {
    this.currentIntervalId = setInterval(() => {
      this.slidesWrapper.scrollBy({ left: distancePerInterval });
    }, intervalTime);
  };
}
