import $ from "jquery";
import { parse } from "postcss";

function openPopup(newsid) {
    const $popup = $("#newspopup"+newsid);
    $popup.fadeIn(200);
}

function closePopup() {
    $(".newspopup:visible").fadeOut(200);
}

$(function() {
    $(document).on("click", ".newspopup .closebutton", function() {
        closePopup();
    });

    $(document).on("click", ".newspopup", function(ev) {
        const bubbled = (ev.target != this);
        if(!bubbled) {
            //modal BG clicked
            closePopup();
        }
    });

    $("[data-opennewspopup]").on("click", function() {
        const newsid = $(this).data("opennewspopup");
        window.location.hash = "news-"+newsid;
        openPopup(newsid);
    });

    function parseHash() {
        const hash = window.location.hash;
        if(hash.startsWith("#news-")) {
            const newsid = hash.substring(6);
            openPopup(newsid);
        }
    }

    parseHash();
});