import { ready } from "./utils.js";

ready(() => {
  const hamburger = document.getElementById("hamburger");

  if (hamburger) {
    hamburger.addEventListener("click", () => {
      hamburger.classList.toggle("is-active");
    });
  }
});
