import $ from "jquery";
import "jquery.scrollto";
// npm install jquery.scrollto

$(function() {
    $(".hero .scrolldownarrow").on("click", function() {
        const $scrolldownarrow = $(this);
        const $hero = $scrolldownarrow.closest(".hero");
        const $nextElement = $hero.next();
        $(window).scrollTo($nextElement, 500);
    });
});

