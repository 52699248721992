const swiper = new Swiper('.swiper', {
    //loop: true,
    
    pagination: {
        //el: '.swiper-pagination',
        el: '.paginationbuttons',
        clickable: true
    },

    //navigation: {
    //    nextEl: '.swiper-button-next',
    //    prevEl: '.swiper-button-prev'
    //}
});


/*
//jQuery version

import $ from "jquery";

$(function() {

    $(".swiper").each(function() {
        const $swiper = $(this);
        const swiper = new Swiper($swiper.get(0), {
            
            pagination: {
                el: $swiper.find('.paginationbuttons').get(0),
                clickable: true
            },
        
        });
    
    });
    
});
*/